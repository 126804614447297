import { useShowSnackbar } from "@octopusdeploy/design-system-components";
import { useMutation } from "@octopusdeploy/octopus-react-client";
import type { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { isEqual } from "lodash";
import type { ReactNode, SetStateAction } from "react";
import React, { useCallback, useRef, useMemo, useState } from "react";
import { v4 } from "uuid";
import type { FieldErrors } from "~/components/DataBaseComponent/Errors";
import { useNavigationBlocker } from "~/components/OctopusRouter/NavigationBlocker";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { toggleExpanders } from "~/components/form/Sections/ExpansionButtons";
import { ExpansionButtons } from "~/components/form/index";
interface FormVNextProps {
    children: ReactNode;
    formId: string;
    fieldErrors: FieldErrors | undefined;
}
export function FormVNext({ children, fieldErrors, formId }: FormVNextProps) {
    const formRef = useRef<HTMLFormElement | null>(null);
    return (<form ref={formRef} id={formId} onSubmit={(event) => {
            // Normally we don't need to prevent the default behavior.
            // But if there doesn't happen to be a submit button associated with the form,
            // then the default behavior involves navigating which we always want to avoid.
            // So we are being defensive here, which ensures we will never cause an unintended navigation.
            event.preventDefault();
        }} onKeyDown={(event) => {
            if (event.ctrlKey && event.key === "Enter") {
                // This replicates the same thing that happens when you press enter on an input in a form
                // From https://www.w3.org/TR/2018/SPSD-html5-20180327/forms.html#the-input-element
                // "A form element's default button is the first submit button in tree order whose form owner is that form element."
                clickFirstSubmitButtonInForm(formRef.current);
            }
        }}>
            <ExpansionButtons errors={fieldErrors}/>
            <AnalyticLinkLocationProvider location="Paper Form">{children}</AnalyticLinkLocationProvider>
        </form>);
}
interface UseFormConfiguration<Model> {
    initialModel: Model;
    formName: string;
    submitPermission?: PermissionCheckProps;
    onSubmit: (repository: Repository, model: Model) => Promise<Model>;
    snackbarSaveText?: string;
    afterSubmit?: (repository: Repository, updatedModel: Model) => Promise<void>;
}
interface UseFormReturnValue<Model> {
    model: Model;
    setModel: (value: SetStateAction<Model>) => void;
    submit: () => Promise<void>;
    isSubmitting: boolean;
    createSaveAction: CreateSaveAction;
    formProps: FormProps;
    // This returns a promise so that you can wait for a render to happen after the form is reset
    // This can be used to reset the form state so that navigation can happen
    resetForm: () => Promise<void>;
}
interface FormProps {
    fieldErrors: FieldErrors;
    formId: string;
}
type CreateSaveAction = (config?: {
    saveButtonLabel?: string;
    saveButtonBusyLabel?: string;
}) => SaveFormAction;
interface SaveFormAction {
    type: "button";
    formId: string;
    label: string;
    disabled: boolean;
    busyLabel: string;
    onClick: (event: React.MouseEvent | undefined) => Promise<unknown>;
}
export function useForm<FormModel>({ initialModel, onSubmit, formName, afterSubmit, submitPermission, snackbarSaveText }: UseFormConfiguration<FormModel>): UseFormReturnValue<FormModel> {
    const [model, setModel] = useState<FormModel>(initialModel);
    const [cleanModel, setCleanModel] = useState<FormModel>(initialModel);
    const showSnackbar = useShowSnackbar();
    const resetCleanModel = useCallback(async (newCleanModel: FormModel) => {
        setModel(newCleanModel);
        setCleanModel(newCleanModel);
        // The previous setModel/setCleanModel results in a "clean" form, which allows navigation
        // This is important because we sometimes immediately navigate in afterSubmit
        // This function call returns control to react, allowing this render to occur before calling afterSubmit
        await yieldToAllowRenderingWhichUnblocksNavigation();
    }, []);
    const { execute: submit, isExecuting: isSubmitting, error, } = useMutation({
        name: formName,
        action: async (repository) => await onSubmit(repository, model),
        afterComplete: async (repository, updatedModel) => {
            await resetCleanModel(updatedModel);
            await afterSubmit?.(repository, updatedModel);
            showSnackbar(snackbarSaveText ?? "Details updated");
            toggleExpanders(false);
        },
    });
    const isClean = useMemo(() => isEqual(model, cleanModel), [model, cleanModel]);
    useNavigationBlocker({
        when: !isClean,
        confirmationText: "If you leave this page, any changes you have made will be lost. Are you sure you wish to leave this page?",
        onSaveClick: submit,
        saveLabel: "Save changes",
    });
    const formId = useMemo(() => `form-${sanitizeFormNameForId(formName)}-${v4()}`, [formName]);
    const createSaveAction: CreateSaveAction = (config) => {
        const { saveButtonLabel = "Save", saveButtonBusyLabel = "Saving" } = config ?? {};
        const submitPermissionCheckResult = hasPermissionToSubmit(submitPermission);
        const label = submitPermissionCheckResult.hasPermission ? saveButtonLabel : `${submitPermissionCheckResult.requiredPermissions.join(", ")} permission required`;
        return {
            type: "button",
            formId,
            label,
            busyLabel: saveButtonBusyLabel,
            disabled: !submitPermissionCheckResult.hasPermission || isClean,
            onClick: submit,
        };
    };
    const fieldErrors = useMemo(() => getFieldErrors(error), [error]);
    const formProps: FormProps = {
        fieldErrors,
        formId,
    };
    const resetForm = useCallback(async () => {
        await resetCleanModel(cleanModel);
    }, [cleanModel, resetCleanModel]);
    return { model, setModel, submit, isSubmitting, createSaveAction, formProps, resetForm };
}
function getFieldErrors(possibleError: Error | null): FieldErrors {
    if (possibleError === null) {
        return {};
    }
    if (possibleError instanceof OctopusError) {
        return possibleError.Details ?? {};
    }
    return {};
}
function yieldToAllowRenderingWhichUnblocksNavigation() {
    return new Promise((resolve) => setTimeout(resolve));
}
function hasPermissionToSubmit(permission: PermissionCheckProps | undefined): {
    hasPermission: true;
} | {
    hasPermission: false;
    requiredPermissions: Permission[];
} {
    if (permission === undefined || isAllowed(permission)) {
        return { hasPermission: true };
    }
    const requiredPermissions = Array.isArray(permission.permission) ? permission.permission : [permission.permission];
    return { hasPermission: false, requiredPermissions };
}
function clickFirstSubmitButtonInForm(form: HTMLFormElement | null) {
    const elements = form === null ? [] : Array.from(form.elements);
    const firstSubmitButton = elements.find(isSubmitButton);
    firstSubmitButton?.click();
}
function isSubmitButton(element: Element): element is HTMLButtonElement {
    return "type" in element && element.type === "submit" && element.tagName === "BUTTON";
}
// Form names must not contain whitespace
function sanitizeFormNameForId(formName: string) {
    return formName.replace(/\s/g, "-");
}
