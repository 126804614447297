import { css, cx } from "@emotion/css";
import { Badge, Button, CollapseColumnIcon, EditIcon, ExpandColumnIcon, scrollbarStyles, TenantsIcon, Tooltip, UnavailableIcon, useIsHovered, useIsMobile } from "@octopusdeploy/design-system-components";
import { borderRadius, letterSpacing, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DetailedDashboardItemResource, DetailedDashboardProjectGroup, EnvironmentWithDeployments, InitialDashboardProjectGroup, ProjectsPageDeployment, ProjectsPageEnvironmentDeployment, ProjectsPageProjectGroup, TenantDeploymentProgress, } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Fragment, useState } from "react";
import { DeploymentTaskPopover } from "~/areas/dashboard/DashboardOverview/DeploymentTaskPopover";
import { useCollapsableColumns } from "~/areas/dashboard/DashboardOverview/useCollapsableColumns";
import { ProjectsDashboardRowHeaderContent, ProjectsDashboardRowHeaderSkeleton } from "~/areas/dashboard/ProjectsDashboard/ProjectsDashboardRowHeaderContent";
import type { FavouriteProjectsData } from "~/areas/projects/components/DashboardDataSource/DataSet";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
import TaskStatusIcon from "~/areas/projects/components/TaskStatusIcon";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink";
import DateFormatter from "~/utils/DateFormatter";
interface ProjectGroupsProps {
    projectGroups: InitialDashboardProjectGroup[] | DetailedDashboardProjectGroup[] | ProjectsPageProjectGroup[];
    onAddProjectToGroup: (projectGroupId: string) => void;
    favouriteProjectsData: FavouriteProjectsData;
    spaceId: string;
}
export function DashboardProjectGroups({ projectGroups, favouriteProjectsData, spaceId, onAddProjectToGroup }: ProjectGroupsProps) {
    return (<div className={projectGroupsStyles}>
            {projectGroups.map((group) => (<DashboardProjectGroup key={group.Id} projectGroup={group} favouriteProjectsData={favouriteProjectsData} spaceId={spaceId} onAddProjectToGroup={onAddProjectToGroup}/>))}
        </div>);
}
export function ProjectGroupDeploymentsSkeleton() {
    return (<div className={projectGroupStyles}>
            <ProjectGroupHeaderSkeleton />
            <div className={cx(deploymentsTableContainerStyle, scrollbarStyles)}>
                <table>
                    <thead>
                        <tr className={deploymentsTableColumnHeaderStyles}/>
                    </thead>
                    <tbody>
                        {[...Array(3)].map((_item, index) => (<tr key={index}>
                                <ProjectsDashboardRowHeaderSkeleton />
                                <ProjectDeploymentRowSkeleton />
                            </tr>))}
                    </tbody>
                </table>
            </div>
        </div>);
}
const projectGroupsStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[24],
});
interface DashboardProjectGroupProps {
    favouriteProjectsData: FavouriteProjectsData;
    projectGroup: InitialDashboardProjectGroup | DetailedDashboardProjectGroup | ProjectsPageProjectGroup;
    onAddProjectToGroup: (projectGroupId: string) => void;
    spaceId: string;
}
function DashboardProjectGroup({ projectGroup, favouriteProjectsData, spaceId, onAddProjectToGroup }: DashboardProjectGroupProps) {
    const showingPartialResults = projectGroup.Projects.length !== projectGroup.TotalProjects;
    return (<div className={projectGroupStyles}>
            <ProjectGroupHeader projectGroupId={projectGroup.Id} title={projectGroup.Name} projectCount={projectGroup.TotalProjects} spaceId={spaceId}/>
            <ProjectGroupDeploymentsTable group={projectGroup} favouriteProjectsData={favouriteProjectsData} onAddProjectToGroup={onAddProjectToGroup}/>
            {showingPartialResults && (<div className={continuedOverPageStyles}>
                    Showing {projectGroup.Projects.length} of {projectGroup.TotalProjects}
                </div>)}
        </div>);
}
const continuedOverPageStyles = css({
    display: "flex",
    justifyContent: "end",
    padding: `${space[6]} ${space[16]} ${space[6]} ${space[16]}`,
    font: text.interface.body.default.small,
    color: themeTokens.color.text.tertiary,
    backgroundColor: themeTokens.color.background.secondary.default,
});
const projectGroupStyles = css({
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${themeTokens.color.border.primary}`,
    borderRadius: borderRadius.medium,
});
interface ProjectGroupHeaderProps {
    projectGroupId: string;
    title: string;
    description?: string;
    projectCount: number;
    spaceId: string;
}
function ProjectGroupHeader({ projectGroupId, title, description, projectCount, spaceId }: ProjectGroupHeaderProps) {
    const groupHeaderSectionRef = React.useRef<HTMLHeadingElement>(null);
    const isHovered = useIsHovered(groupHeaderSectionRef);
    const isMobile = useIsMobile();
    return (<div className={projectGroupHeaderStyles.root}>
            <h2 ref={groupHeaderSectionRef} className={projectGroupHeaderStyles.groupName}>
                {title}
                <Badge count={projectCount} size="small" variant="default"/>
                <div className={cx(hideEditIconContainerStyles, { [showEditIconContainerStyles]: isMobile || isHovered })}>
                    <InternalLink to={links.editProjectGroupPage.generateUrl({ spaceId, projectGroupId })}>
                        <EditIcon size={20}/>
                    </InternalLink>
                </div>
            </h2>
            {description && <p>{description}</p>}
        </div>);
}
const hideEditIconContainerStyles = css({
    display: "none",
});
const showEditIconContainerStyles = css({
    display: "block",
});
function ProjectGroupHeaderSkeleton() {
    return (<div className={projectGroupHeaderStyles.root}>
            <div className={projectGroupHeaderStyles.skeleton}>
                <Skeleton shape={"Rounded"} borderRadius={"small"}/>
            </div>
        </div>);
}
const projectGroupHeaderStyles = {
    root: css({
        display: "flex",
        flexDirection: "column",
        padding: `${space[16]} ${space[16]} ${space[12]} ${space[16]}`,
        borderBottom: `1px solid ${themeTokens.color.border.primary}`,
    }),
    groupName: css({
        all: "unset",
        font: text.interface.body.bold.base,
        letterSpacing: letterSpacing.wider,
        display: "flex",
        gap: space[8],
        alignItems: "center",
    }),
    skeleton: css({
        width: "16.25rem",
        height: "1.5rem",
    }),
};
interface ProjectGroupDeploymentsTableProps {
    favouriteProjectsData: FavouriteProjectsData;
    onAddProjectToGroup: (projectGroupId: string) => void;
    group: InitialDashboardProjectGroup | DetailedDashboardProjectGroup | ProjectsPageProjectGroup;
}
function isDetailedDashboardProjectGroup(group: InitialDashboardProjectGroup | DetailedDashboardProjectGroup | ProjectsPageProjectGroup): group is DetailedDashboardProjectGroup | ProjectsPageProjectGroup {
    return group && "Environments" in group && Boolean(group.Environments);
}
function ProjectGroupDeploymentsTable({ group, favouriteProjectsData, onAddProjectToGroup }: ProjectGroupDeploymentsTableProps) {
    return isDetailedDashboardProjectGroup(group) ? (<ProjectGroupDetailedDeploymentsTable group={group} favouriteProjectsData={favouriteProjectsData} onAddProjectToGroup={onAddProjectToGroup}/>) : (<ProjectGroupInitialDeploymentsTable favouriteProjectsData={favouriteProjectsData} group={group} onAddProjectToGroup={onAddProjectToGroup}/>);
}
interface ProjectGroupDetailedDeploymentsTableProps {
    favouriteProjectsData: FavouriteProjectsData;
    onAddProjectToGroup: (projectGroupId: string) => void;
    group: DetailedDashboardProjectGroup | ProjectsPageProjectGroup;
}
type DeploymentDetailPopoverState = {
    element: HTMLElement | null;
    deployment: DetailedDashboardItemResource | ProjectsPageDeployment;
    environment: EnvironmentWithDeployments | ProjectsPageEnvironmentDeployment;
};
function ProjectGroupDetailedDeploymentsTable({ group, favouriteProjectsData, onAddProjectToGroup }: ProjectGroupDetailedDeploymentsTableProps) {
    const { isColumnCollapsed, collapseColumn } = useCollapsableColumns();
    const [popoverState, setPopoverState] = useState<DeploymentDetailPopoverState | null>(null);
    const collapseAllEnvironmentHeader = React.useMemo(() => {
        const hasAllCollapsed = group.Environments.every((x) => isColumnCollapsed(`${group.Id}-${x.Id}`) === true);
        const allEnvironmentsKey = "{{ALL}}";
        return (<AllEnvironmentsColumnHeader key={allEnvironmentsKey} name={hasAllCollapsed ? "Expand All" : "Collapse All"} isCollapsed={hasAllCollapsed} toggleCollapse={() => {
                group.Environments.map((x) => {
                    collapseColumn(`${group.Id}-${x.Id}`, () => !hasAllCollapsed);
                });
            }}/>);
    }, [collapseColumn, group.Environments, group.Id, isColumnCollapsed]);
    if (group.TotalProjects === 0) {
        return <EmptyProjectGroup onAddProjectToGroup={() => onAddProjectToGroup(group.Id)}/>;
    }
    return (<div className={cx(deploymentsTableContainerStyle, scrollbarStyles)}>
            {popoverState && <DeploymentTaskPopover anchorEl={popoverState.element} deployment={popoverState.deployment} environment={popoverState.environment.Name} open={!!popoverState} onClose={() => setPopoverState(null)}/>}
            <table className={tableStyle}>
                <thead>
                    <tr>
                        <th scope={"col"} className={cx(tableCellExpandedStyles, stickyColumn)}>
                            {collapseAllEnvironmentHeader}
                        </th>
                        {group.Environments.map((env) => (<EnvironmentColumnHeader key={env.Id} name={env.Name} isCollapsed={isColumnCollapsed(`${group.Id}-${env.Id}`)} toggleCollapse={() => collapseColumn(`${group.Id}-${env.Id}`, (prev) => !prev)}/>))}
                    </tr>
                </thead>
                <tbody>
                    {group.Projects.map((project, index, arr) => {
            return (<tr key={`deploymentRow${project.Id}`} className={cx({ [disabledProjectRowStyles]: project.IsDisabled })}>
                                <th scope={"row"} className={cx(tableCellExpandedStyles, stickyColumn, { [lastRowHeaderStyle]: index === arr.length - 1 })}>
                                    <ProjectsDashboardRowHeaderContent favouriteProjectsData={favouriteProjectsData} project={project}/>
                                </th>
                                {project.Environments.map((environment) => {
                    const latestDeployment = environment.Deployment;
                    const isCollapsed = isColumnCollapsed(`${group.Id}-${environment.Id}`);
                    const tenantProgress = getDeploymentTenantProgress(environment);
                    if (!latestDeployment) {
                        return (<DeploymentStatusCell isCollapsed={isCollapsed} key={environment.Id}>
                                                <NoData isCollapsed={isCollapsed}/>
                                            </DeploymentStatusCell>);
                    }
                    return (<DeploymentStatusCell isCollapsed={isCollapsed} key={latestDeployment.DeploymentId}>
                                            <DeploymentTaskStatus projectSlug={project.Slug} deployment={latestDeployment} isCollapsed={isCollapsed} tenantProgress={tenantProgress}/>
                                        </DeploymentStatusCell>);
                })}
                            </tr>);
        })}
                </tbody>
            </table>
        </div>);
}
interface EmptyProjectGroupProps {
    onAddProjectToGroup: () => void;
}
function EmptyProjectGroup({ onAddProjectToGroup }: EmptyProjectGroupProps) {
    return (<div className={emptyProjectGroupStyles.root}>
            <div className={emptyProjectGroupStyles.callToAction}>
                <Button label={"Add Project"} onClick={onAddProjectToGroup} importance={"secondary"}/>
            </div>
        </div>);
}
const emptyProjectGroupStyles = {
    root: css({
        padding: space["24"],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }),
    callToAction: css({
        rowGap: space["8"],
        padding: space["48"],
        alignSelf: "stretch",
        justifyContent: "center",
        borderRadius: borderRadius.small,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: themeTokens.color.background.secondary.default,
        color: themeTokens.color.text.secondary,
    }),
};
const stickyColumn = css({
    position: "sticky",
    left: 0,
    backgroundColor: themeTokens.color.background.primary.default,
});
const lastRowHeaderStyle = css({
    borderRadius: "6px",
});
const tableStyle = css({
    borderSpacing: "unset",
});
function getDeploymentTenantProgress(value: ProjectsPageEnvironmentDeployment | EnvironmentWithDeployments): TenantDeploymentProgress | undefined {
    return "TenantDeploymentProgress" in value ? value.TenantDeploymentProgress : undefined;
}
const deploymentsTableContainerStyle = css({
    width: "100%",
    overflowX: "auto",
});
const disabledProjectRowStyles = css({
    opacity: 0.4,
});
export const deploymentsTableColumnHeaderStyles = css({
    height: "2.25rem",
});
const deploymentStatusCellCollapsedStyles = css({
    minWidth: "104px",
    maxWidth: "104px",
});
const tableCellExpandedStyles = css({
    minWidth: "14rem",
});
interface DeploymentStatusCellProps {
    children: React.ReactNode;
    isCollapsed: boolean;
}
function DeploymentStatusCell({ children, isCollapsed }: DeploymentStatusCellProps) {
    return (<td>
            <div className={cx(deploymentCellStyles, isCollapsed ? deploymentStatusCellCollapsedStyles : tableCellExpandedStyles)}>{children}</div>
        </td>);
}
export const deploymentCellStyles = css({
    display: "flex", // Needed to maintain vertically center alignment.
    padding: `${space["12"]} ${space["6"]}`,
    height: "4.75rem",
});
interface ProjectGroupInitialDeploymentsTableProps {
    favouriteProjectsData: FavouriteProjectsData;
    group: InitialDashboardProjectGroup;
    onAddProjectToGroup: (projectGroupId: string) => void;
}
function ProjectGroupInitialDeploymentsTable({ favouriteProjectsData, group, onAddProjectToGroup }: ProjectGroupInitialDeploymentsTableProps) {
    if (group.TotalProjects === 0) {
        return <EmptyProjectGroup onAddProjectToGroup={() => onAddProjectToGroup(group.Id)}/>;
    }
    return (<div className={cx(deploymentsTableContainerStyle, scrollbarStyles)}>
            <table>
                <thead>
                    <tr className={environmentHeaderPlaceholderStyles}/>
                </thead>
                <tbody>
                    {group.Projects.map((project, index, arr) => (<tr key={index}>
                            <th scope={"row"} className={cx(tableCellExpandedStyles, stickyColumn, { [lastRowHeaderStyle]: index === arr.length - 1 })}>
                                <ProjectsDashboardRowHeaderContent favouriteProjectsData={favouriteProjectsData} project={project}/>
                            </th>
                            <ProjectDeploymentRowSkeleton key={project.Id}/>
                        </tr>))}
                </tbody>
            </table>
        </div>);
}
const environmentHeaderPlaceholderStyles = css({
    height: "2.25rem",
});
function ProjectDeploymentRowSkeleton() {
    return (<>
            {[...Array(4)].map((_, index) => (<DeploymentStatusCell key={index} isCollapsed={false}>
                    <div className={deploymentSkeletonStyles}>
                        <Skeleton shape={"Rounded"} borderRadius={"small"}/>
                    </div>
                </DeploymentStatusCell>))}
        </>);
}
const deploymentSkeletonStyles = css({
    height: "1.25rem",
    width: "10.75rem",
    alignSelf: "center",
});
const noDataStyles = css({
    display: "flex",
    columnGap: space["6"],
    font: text.interface.body.bold.medium,
    color: themeTokens.color.text.disabled,
    alignItems: "center",
    padding: space["6"],
});
interface NoDataProps {
    isCollapsed: boolean;
}
function NoData({ isCollapsed }: NoDataProps) {
    return (<div className={noDataStyles}>
            <UnavailableIcon />
            {!isCollapsed && "No Data"}
        </div>);
}
interface EnvironmentColumnHeaderProps {
    name: string;
    isCollapsed: boolean;
    toggleCollapse: () => void;
}
const environmentColumnHeaderCollapseContainerStyles = css({
    display: "flex",
    alignItems: "center",
});
function EnvironmentColumnHeader({ name, toggleCollapse, isCollapsed }: EnvironmentColumnHeaderProps) {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const isHovered = useIsHovered(containerRef);
    return (<th scope={"col"}>
            <div ref={containerRef} className={cx(environmentColumnHeaderStyles, deploymentsTableColumnHeaderStyles, isCollapsed ? environmentHeaderColumnHeaderCollapsedStyles : tableCellExpandedStyles)} title={name}>
                <div className={cx(environmentColumnHeaderNameStyles, collapsedEnvironmentColumnHeaderNameStyles)}>{name}</div>
                <div className={environmentColumnHeaderCollapseContainerStyles}>
                    <ColumnCollapseButton isCollapsed={isCollapsed} onToggle={toggleCollapse} isVisible={isHovered} columnLabel={name} showLabel={false}/>
                </div>
            </div>
        </th>);
}
function AllEnvironmentsColumnHeader({ name, toggleCollapse, isCollapsed }: EnvironmentColumnHeaderProps) {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const isHovered = useIsHovered(containerRef);
    return (<div ref={containerRef} className={cx(environmentColumnHeaderStyles, deploymentsTableColumnHeaderStyles, allEnvironmentHeaderColumnHeaderStyles)}>
            <div className={environmentColumnHeaderCollapseContainerStyles}>
                <ColumnCollapseButton isCollapsed={isCollapsed} onToggle={toggleCollapse} isVisible={isHovered} columnLabel={name} showLabel={true}/>
            </div>
        </div>);
}
const environmentColumnHeaderNameStyles = css({
    flex: 1,
});
const collapsedEnvironmentColumnHeaderNameStyles = css({
    textOverflow: "ellipsis",
    overflow: "hidden",
});
interface ColumnCollapseButtonProps {
    isCollapsed: boolean;
    onToggle: () => void;
    isVisible: boolean;
    columnLabel: string;
    showLabel: boolean;
}
const columnCollapseButtonStyles = css({
    backgroundColor: "transparent",
    border: "none",
    color: "inherit",
    padding: 0,
    cursor: "pointer",
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
    justifyContent: "end",
    font: text.table.sort.xSmall,
    letterSpacing: letterSpacing.wider,
    textTransform: "uppercase",
});
const hiddenColumnCollapseButtonStyles = css({
    display: "none",
});
function ColumnCollapseButton({ isCollapsed, onToggle, isVisible, columnLabel, showLabel }: ColumnCollapseButtonProps) {
    return (<button className={cx(columnCollapseButtonStyles, isVisible ? false : hiddenColumnCollapseButtonStyles)} onClick={onToggle} aria-label={`${isCollapsed ? "Expand" : "Collapse"} ${columnLabel}`}>
            {showLabel && columnLabel}
            {isCollapsed ? <ExpandColumnIcon size="xSmall"/> : <CollapseColumnIcon size={16}/>}
        </button>);
}
const environmentColumnHeaderStyles = css({
    color: themeTokens.color.text.tertiary,
    font: text.table.sort.xSmall,
    letterSpacing: letterSpacing.wider,
    textTransform: "uppercase",
    textAlign: "start",
    paddingTop: space["16"],
    paddingLeft: space["8"],
    paddingRight: space["8"],
    display: "flex",
    justifyContent: "end",
    "&:hover": {
        color: themeTokens.color.text.primary,
    },
});
const allEnvironmentHeaderColumnHeaderStyles = css({
    textAlign: "end",
});
const environmentHeaderColumnHeaderCollapsedStyles = css({
    minWidth: "6.5rem",
    maxWidth: "6.5rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
});
const releaseVersionTextStyles = css({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "8.5rem", // Needed for ellipsis to work
    font: text.interface.body.bold.medium,
    lineHeight: "1.42",
    color: themeTokens.color.text.primary,
    flex: 1,
});
const deploymentTaskStatusStyles = {
    root: css({
        display: "flex",
        flexDirection: "row",
        columnGap: space["8"],
        padding: space["6"],
        overflow: "hidden", // Fallback/safety-net in case dates or anything else overflows here.
        minHeight: "3.25rem",
        borderRadius: borderRadius.small,
        "&:hover": {
            cursor: "pointer",
            outline: `1px solid ${themeTokens.color.border.selected}`,
            backgroundColor: themeTokens.color.background.primary.hovered,
            [`& .${releaseVersionTextStyles}`]: {
                color: themeTokens.color.text.link.default,
            },
        },
        ":focus-visible": {
            boxShadow: themeTokens.shadow.focused,
            outline: "none",
        },
    }),
    taskIcon: css({
        marginTop: space["4"],
    }),
    task: css({
        display: "flex",
        flexDirection: "column",
        flex: 1,
    }),
    releaseVersionContainer: css({
        display: "flex",
        alignItems: "center",
    }),
    taskTimeText: css({
        color: themeTokens.color.text.secondary,
        font: text.interface.body.default.xSmall,
        lineHeight: "1.42",
    }),
};
interface DeploymentTaskStatusProps {
    projectSlug: string;
    deployment: DetailedDashboardItemResource | ProjectsPageDeployment;
    isCollapsed: boolean;
    tenantProgress: TenantDeploymentProgress | undefined;
}
const DeploymentTaskStatus = ({ projectSlug, deployment, isCollapsed, tenantProgress }: DeploymentTaskStatusProps) => {
    const containerRef = React.useRef<HTMLAnchorElement>(null);
    const relevantTime = deployment.CompletedTime ?? deployment.QueueTime;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const link = links.deploymentDetailsPage.generateUrl({ spaceId: repository.spaceId!, projectSlug, releaseVersion: deployment.ReleaseVersion, deploymentId: deployment.DeploymentId });
    return (<InternalLink ref={containerRef} to={link} className={deploymentTaskStatusStyles.root}>
            <div className={deploymentTaskStatusStyles.taskIcon}>
                <TaskStatusIcon item={deployment} smallIcon={false}/>
            </div>
            {!isCollapsed && (<div className={deploymentTaskStatusStyles.task}>
                    <div className={deploymentTaskStatusStyles.releaseVersionContainer}>
                        <span className={releaseVersionTextStyles} title={deployment.ReleaseVersion}>
                            {deployment.ReleaseVersion}
                        </span>
                        {deployment.TenantId ? (tenantProgress === undefined ? (<StyledTenantsIcon />) : (<Badge size={"xSmall"} variant={"neutral"} label={`${tenantProgress.CompletedDeployments}/${tenantProgress.TotalConnectedTenants}`}/>)) : (<SpacerIcon />)}
                    </div>
                    <span className={deploymentTaskStatusStyles.taskTimeText}>{DateFormatter.dateToShortFormat(relevantTime)}</span>
                </div>)}
            {isCollapsed && <div className={collapsedDeploymentTaskDetailStyles}>{deployment.TenantId ? <StyledTenantsIcon /> : <SpacerIcon />}</div>}
        </InternalLink>);
};
const collapsedDeploymentTaskDetailStyles = css({
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
});
function StyledTenantsIcon() {
    return (<Tooltip content="Tenanted">
            <div className={tenantIconStyles}>
                <TenantsIcon />
            </div>
        </Tooltip>);
}
function SpacerIcon() {
    return (<div className={cx(tenantIconStyles, visuallyHidden)}>
            <TenantsIcon />
        </div>);
}
const tenantIconStyles = css({
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
    color: themeTokens.color.icon.secondary,
    cursor: "pointer",
    display: "flex",
    width: "1.25rem",
    height: "1.25rem",
});
const visuallyHidden = css({
    visibility: "hidden",
});
